

.messageContainer {
  width: 100%;
  display: flex;
}

.message {
  font-size: 1.5rem; 
  padding: 1rem;
  margin: 6px 8px 0px 8px;
  word-wrap: break-word;
  max-width: 50%;
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
display: flex;
align-items: center;

}

.yourMessage {
  background: #23D172;
  box-shadow: 0px 0px 20px 6px rgba(204, 204, 204, 0.3);
  border-radius: 20px 0px 20px 20px;
  margin-left: auto;
  color: #ffffff;
  
}

.partnerMessage {
  background: #E8F1F5;
box-shadow: 0px 0px 20px 6px rgba(204, 204, 204, 0.3);
border-radius: 0px 20px 20px 20px;
  
  color: #094166;
}

.systemMessage {
  background-color: rgb(27, 235, 37);
  border-color: black;
}

@media screen and (max-width: 860px) {
  .message {
    font-size: 1rem;
  }
}
