@import "../../variables.scss";

.loading {
  height: calc(100vh - 70px);
  width: calc(65vw);
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  background-color: transparent;
  z-index: 4;

  font-family: "Great Vibes", cursive;
  font-weight: 500;
  color: #ffc200;
}

@media screen and (max-width: 860px) {
  .loading {
    height: calc(30vh);
    font-size: 2rem;
    width: 100%;
  }
}
