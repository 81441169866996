@import url('https://fonts.googleapis.com/css?family=Maven+Pro:400,700');

.Pcontent {

    font-family: 'Maven Pro', sans-serif;
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;
    color: black;
    
    
  }